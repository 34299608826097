import React from 'react'
import { trainingData } from '../data/trainingData'
import Layout from '../components/layout'
import Seo from '../components/seo'
import TrainingCard from '../components/trainingsComponents/trainingCard'
import TrainingHeader from '../components/trainingsComponents/trainingHeader'
import TrainingIntroduction from '../components/trainingsComponents/trainingIntroduction'

const Trainings = () => {
    return (
        <Layout>
            <Seo title="Training Center|Ricta Technologies" description="List of training courses for Ricta Technologies" />
            <div>
                <TrainingHeader mainTitle={'Training Center'} text={'We offer flexible, easy to access and affordable trainings that aim to bring a significant improvement in how individuals progress in their carreer and help professionals stay tuned to latest trends, technics and best practices in the cybersecurity field. All trainings are held by expert trainers with 10+ years of field experience.'} color={'yellow'}/>
                <TrainingIntroduction/>
                <div className="text-xl md:text-2xl lg:text-3xl text-white bg-mainColor p-1 mb-4 text-center">Training Catalogue</div>
                <div className="sm:text-xl text-lg text-mainColor my-4 text-center">Explore our course offer and pick the ones suitable for you. Enroll now and start learning with us! </div>
                <div className="max-w-screen-xl w-4/5 md:w-full m-auto">
                    {trainingData.map((training,i) => {
                        return(
                            <div key={i}>
                                <p className="text-lightBlue2 sm:text-xl text-left font-bold px-4">{training.heading}</p>
                                <div className="grid md:grid-cols-3 sm:grid-cols-1">
                                    <TrainingCard data={training.data}/>
                                </div>
                            </div>
                        )})}
                </div>
            </div>
        </Layout>
       
    )
}
export default Trainings