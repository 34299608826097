import React from 'react'
import icon1 from '../images/streamline-icon-tag-free@48x48.svg'
import icon2 from '../images/streamline-icon-tag-hot-price@48x48.svg'
import icon3 from '../images/streamline-icon-ranking-stars-ribbon@48x48.svg'
export const trainingData = [
    {
        heading: 'Specialized courses',
        data: [
            {
                title: 'A1. Cyber OPSEC for VIP clients',
                duration: '2 days',
                description:(<>
                Day 1 program – OPSEC Training <br/>
                Day 2 program – OPSEC consultancy for participants
                </>)
            },
            {
                title: 'A2. Cyber OPSEC for StartUps',
                duration: '2 days',
                description: (<>
                Day 1 program – OPSEC Training <br/>
                Day 2 program – OPSEC consultancy for participants
                </>),
                icon: icon2
            },
            {
                title: 'A3. Crypto and NFT for Law Enforcement',
                duration: '1 day',
                description: 'The course provides in-depth knowledge on how to investigate crypto and NFT related crimes. ',
                icon: icon3
            }
        ]
    },
    {
        heading: 'Comprehensive training courses with fundamental knowledge and real case scenario discussions.',
        data: [
            {
                title: 'B1. OSINT for Cyber Threat Intelligence',
                duration: '2 days',
                description: 'OSINT Introduction; Tactics, Techniques and Procedures; OPSEC and Investigation profiles; People Search; Crypto coins and NFT; Dark Web Investigations; Forensic Evidence Collection'
            },
            {
                title: 'B2. OSINT for Law Enforcement',
                duration: '2 days',
                description: 'OSINT Introduction; Tactics, Techniques and Procedures; OPSEC and Investigation profiles; People Search; Dark Web Investigations; Forensic Evidence Collection.'
            },
            {
                title:'B3. Cyber OPSEC for Law Enforcement',
                duration: (<>
                2 days
                </>),
                description:'OPSEC Introduction; Real Life Fails; Create an Investigation Profile; Crypto Coins and NFT; Zero Attribution Communications, Networks, Payments',
                icon: icon1
            }]
    },
    /*{
        heading: 'Trainings showcasing real case studies with focus on job role tasks execution',
        data: [
            {
                title: 'C1. Digital Forensics through Case Studies',
                duration:'1 day',
                description: 'The course provides the necessary knowledge to be an effective and efficient Digital Forensics practitioner and how to effectively collect, manage and use digital evidence.'
            },
            {
                title: 'C2. Mobile Forensics through Case Studies',
                duration: '1 day',
                description:'The course provides the basic knowledge capsule to perform mobile forensic and runs the participants through real life case study analysis and examples.'
            },
            {
                title: 'C3. Incident Response through Case Studies',
                duration: '1 day',
                description: 'The course provides key Incident Response discipline knowledge and focuses on the daily tasks and challenges of an analyst in such a job role.'
            }]
    }*/  
    
]